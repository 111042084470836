import '~js/turbo'
import { application } from '~js/application'
import { possibleModal, acceptProposal } from '~js/helpers/link_overwriter'
import Dialog from '~js/controllers/application/dialog_controller'
import Modal from '~js/controllers/application/modal_controller'
import Existance from '~js/controllers/application/existance_controller'
import Scroll from '~js/controllers/application/scroll_controller'
import TwilioRoom from '~js/controllers/application/twilio_room_controller'
import Form from '~js/controllers/application/form_controller'

application.register('dialog', Dialog)
application.register('modal', Modal)
application.register('existance', Existance)
application.register('scroll', Scroll)
application.register('twilio-room', TwilioRoom)
application.register('form', Form)

document.addEventListener('DOMContentLoaded', () => {
  possibleModal()
  acceptProposal()
})
